import { Component, h } from "preact";
import introduction from "../../content/introduction.md";
import { getState, GlobalAppState, subscribe } from "../../store/global";
import {
    jhHeaderImage,
    jhLoaded,
    jhProgress,
    path,
    spinner
} from "./index.scss";

class Header extends Component<{}, GlobalAppState> {
    private sub: (() => void) | undefined;

    public componentDidMount() {
        this.sub = subscribe(() => this.setState(getState()));
    }

    public componentWillUnmount() {
        if (this.sub) {
            this.sub();
        }
    }

    public render() {
        return (
            <div class="row jh-header jh-header-center">
                <div class="col-md-2 col-sm-4">
                    <div class={jhProgress}>
                        <img
                            class={
                                this.state.isLoading
                                    ? jhHeaderImage
                                    : `${jhHeaderImage} ${jhLoaded}`
                            }
                            src={
                                this.state.mode &&
                                `assets/img/${this.state.mode}.jpg`
                            }
                        />
                        {this.renderLoadingBar()}
                    </div>
                </div>
                <div class="col-md-6 col-sm-8 jh-headline">
                    <p
                        class="lead"
                        dangerouslySetInnerHTML={{ __html: introduction }}
                    ></p>
                </div>
            </div>
        );
    }

    private renderLoadingBar() {
        if (!this.state.isLoading) {
            return;
        }
        return (
            <svg
                class={spinner}
                width="150px"
                height="150px"
                viewBox="0 0 66 66"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    class={path}
                    fill="none"
                    stroke-width="4"
                    stroke-linecap="round"
                    cx="33"
                    cy="33"
                    r="30"
                />
            </svg>
        );
    }
}

export default Header;
