import { Component, h, Fragment } from "preact";
import {
    getState,
    subscribe,
    GlobalAppState,
    dispatch,
    Actions
} from "../../store/global";
import { jhActiveBtn, jhLogin, jhLoginBtn, jhMoreBtn } from "./index.scss";
import { wait } from "../../common";

class Login extends Component<{}, GlobalAppState> {
    private sub: (() => void) | undefined;
    private pw = "";

    constructor() {
        super();
    }

    public componentDidMount() {
        this.sub = subscribe(() => this.setState(getState()));
    }

    public componentWillUnmount() {
        if (this.sub) {
            this.sub();
        }
    }

    public render() {
        return (
            <form class={jhLogin} onSubmit={e => this.login(e)}>
                <button
                    type="button"
                    onClick={e => this.active(e)}
                    class={
                        this.state.loginActive
                            ? `${jhMoreBtn} ${jhActiveBtn}`
                            : jhMoreBtn
                    }
                />
                {this.state.loginActive && (
                    <Fragment>
                        <input
                            type="password"
                            placeholder="Please, enter your password"
                            value={this.pw}
                            onChange={e => this.handlePasswordChange(e)}
                            class="animated fadeIn"
                        />
                        <button
                            type="submit"
                            class={`${jhMoreBtn} ${jhLoginBtn} animated ${
                                this.state.loginError ? "shake" : ""
                            }`}
                        />
                    </Fragment>
                )}
            </form>
        );
    }

    private handlePasswordChange(event: any) {
        this.pw = event.target.value;
    }

    private async login(e: Event) {
        e.preventDefault();
        await dispatch({ type: Actions.TOGGLE_LOADING });
        try {
            if (this.pw.length > 0) {
                try {
                    await dispatch({
                        type: Actions.LOAD_PRIVATE,
                        value: this.pw
                    });
                } catch (ex) {
                    await this.loginError();
                }
            } else {
                await this.loginError();
            }
        } catch (ex) {
            console.log(ex);
            // TODO: ADD LOGIN ERROR MSG
        } finally {
            this.pw = "";
            await dispatch({ type: Actions.TOGGLE_LOADING });
        }

        return false;
    }

    private async loginError() {
        await dispatch({ type: Actions.TOGGLE_ERROR });
        await wait(500);
        await dispatch({ type: Actions.TOGGLE_ERROR });
    }

    private active(e: Event) {
        e.preventDefault();
        dispatch({ type: Actions.TOGGLE_LOGIN });
        return false;
    }
}

export default Login;
