import { Component, Fragment, h } from "preact";
import {
    Actions,
    dispatch,
    getState,
    GlobalAppState,
    subscribe
} from "../../store/global";
import Login from "../login";

class TimeLine extends Component<{}, GlobalAppState> {
    private sub: (() => void) | undefined;

    public componentDidMount() {
        this.sub = subscribe(() => this.setState(getState()));
    }

    public componentWillUnmount() {
        if (this.sub) {
            this.sub();
        }
    }

    public render() {
        if (!this.state.events) {
            return;
        }

        const entries = this.state.events.map((item: any, index) => (
            <div class={`row animated ${index === 0 ? 'bounceInDown' : 'slideInUp'}`}>
                <div
                    class="col-sm-2 col-md-offset-1 col-md-2 text-right"
                    style="padding-top: 38px;"
                >
                    <h4>
                        {item.from
                            ? new Date(item.from).toLocaleDateString()
                            : ""}
                    </h4>
                    <h6>
                        {(item.today
                            ? "today"
                            : "end: " + new Date(item.date).toLocaleDateString())}
                    </h6>

                    <div class="jh-line hidden-xs">
                        <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                        >
                            <path d="M5 0 L18 12 L5 24 Z" />
                        </svg>
                    </div>

                    <div class="jh-tags">{this.renderTags(item.tags)}</div>

                    <h6>{item.subtitle}</h6>
                </div>

                <div class="col-sm-10 col-md-7 jh-article">
                    <h2>{item.headline}</h2>
                    <article dangerouslySetInnerHTML={{ __html: item.text }} />
                </div>
            </div>
        ));

        return [
            ...entries,
            this.state.loginSuccess ? (
                ""
            ) : (
                <div class="row animated bounceInDown">
                    <div class="col-sm-2 col-md-offset-1 col-md-2 text-right" />
                    <div class="col-sm-10 col-md-7 jh-article">
                        <Login />
                    </div>
                </div>
            )
        ];
    }

    private renderTags(tags: []) {
        return tags.map(tag => {
            if (this.state.loginSuccess) {
                return (
                    <Fragment key={tag}>
                        <span
                            title="Click to exclude a tag."
                            onClick={e => this.excludeTag(e, tag)}
                            class="jh-tag label label-default"
                        >
                            {tag}
                        </span>
                        <br />
                    </Fragment>
                );
            }
            return (
                <Fragment key={tag}>
                    <span class="jh-tag-readonly label label-default">
                        {tag}
                    </span>
                    <br />
                </Fragment>
            );
        });
    }

    private excludeTag(event: Event, tagName: string) {
        event.preventDefault();
        dispatch({ type: Actions.EXCLUDE_TAG, value: tagName });
        return false;
    }
}

export default TimeLine;
