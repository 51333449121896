import { Component, h } from "preact";
import { wait } from "../common";
import {
    Actions,
    dispatch,
    getState,
    GlobalAppState,
    subscribe
} from "../store/global";
import Header from "./header";
import TimeLine from "./time-line";
import { Tools } from "./tools";

if ((module as any).hot) {
    // tslint:disable-next-line:no-var-requires
    require("preact/debug");
}

class App extends Component<{}, GlobalAppState> {
    private sub: (() => void) | undefined;

    public render() {
        return (
            <div
                id="app"
                class={`container-fluid jh-fade-in jh-${this.state.mode}`}
            >
                {this.state.allEvents ? <Header /> : ""}
                {this.state.allEvents ? <TimeLine /> : ""}
                {this.state.loginSuccess ? <Tools /> : ""}
            </div>
        );
    }

    public async componentDidMount() {
        this.sub = subscribe(() => this.setState(getState()));
        await this.load();
    }

    public componentWillUnmount() {
        if (this.sub) {
            this.sub();
        }
    }

    private async load() {
        await dispatch({ type: Actions.LOAD_PUBLIC });
        await wait(500);
        dispatch({ type: Actions.TOGGLE_LOADING });
    }
}

export default App;
