import { Component, Fragment, h } from "preact";
import {
    Actions,
    dispatch,
    getState,
    GlobalAppState,
    subscribe
} from "../../store/global";
import { jhTools } from "./index.scss";

export class Tools extends Component<{}, GlobalAppState> {
    private sub: (() => void) | undefined;

    public componentDidMount() {
        this.sub = subscribe(() => this.setState(getState()));
    }

    public componentWillUnmount() {
        if (this.sub) {
            this.sub();
        }
    }

    public render() {
        if (!this.state.loginSuccess) {
            return;
        }
        return (
            <div class={`${jhTools} col-md-3`}>
                <legend>Print options</legend>
                <label>Look:</label>
                <div class="btn-group" role="group">
                    <button
                        type="button"
                        class={this.isActiveStyle("leger")}
                        onClick={e => this.setStyle(e, "leger")}
                    >
                        Legér
                    </button>
                    <button
                        type="button"
                        class={this.isActiveStyle("pro")}
                        onClick={e => this.setStyle(e, "pro")}
                    >
                        Professional
                    </button>
                </div>
                <label>Excluded:</label>
                <p class="text-sm jh-tags">
                    {this.state.excludedTags.length === 0 ? (
                        <i>Click on a tag to exclude it</i>
                    ) : (
                        this.renderTags()
                    )}
                </p>

                <button class="btn btn-primary btn-sm btn-block" onClick={(e) => window.print()}>Print</button>
            </div>
        );
    }

    private renderTags() {
        return this.state.excludedTags.map(tag => (
            <Fragment>
                <span
                    onClick={e => this.includeTag(e, tag)}
                    class="jh-tag label label-default"
                >
                    {tag}
                </span>
            </Fragment>
        ));
    }

    private includeTag(event: Event, tagName: string) {
        event.preventDefault();
        dispatch({ type: Actions.INCLUDE_TAG, value: tagName });
        return false;
    }

    private isActiveStyle(mode: string): string | undefined {
        return `btn btn-default ${this.state.mode === mode &&
            "active btn-primary"} btn-xs`;
    }

    private setStyle(e: Event, mode: "pro" | "leger") {
        e.preventDefault();
        dispatch({ type: Actions.CHANGE_MODE, value: mode });
        return false;
    }
}
