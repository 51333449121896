import { orderBy } from "lodash-es";

/**
 * This file contains a simple redux like
 * store to avoid using a sledge-hammer to
 * crack a nut.
 *
 * Arg, by looking at this a second time it
 * might have been better to use some proper
 * solution :/
 */
interface GlobalAppState {
    isLoading: boolean;
    loginActive: boolean;
    password: string;
    events: any[];
    allEvents: any[];
    loginSuccess: boolean;
    mode: "pro" | "leger";
    excludedTags: string[];
    loginError: boolean;
}

let listeners: any[] = [];
let globalState: GlobalAppState = getInitState();

enum Actions {
    TOGGLE_LOADING,
    TOGGLE_ERROR,
    LOAD_PUBLIC,
    LOAD_PRIVATE,
    TOGGLE_LOGIN,
    CHANGE_MODE,
    EXCLUDE_TAG,
    INCLUDE_TAG
}

interface Action {
    type: Actions;
    value?: any;
}

const simpleGlobalReducer = async (
    state: GlobalAppState = globalState,
    action: Action
) => {
    switch (action.type) {
        case Actions.TOGGLE_LOADING:
            return { ...state, isLoading: !state.isLoading };
        case Actions.LOAD_PUBLIC:
            const events = (await import(`../content/public`)).default;

            if (state.password) {
                state = await simpleGlobalReducer(state, {
                    type: Actions.LOAD_PRIVATE,
                    value: state.password
                });
            }

            const allPublicEvents = orderBy(
                [...state.allEvents, ...events],
                "date",
                "desc"
            );
            return {
                ...state,
                allEvents: allPublicEvents,
                events: allPublicEvents.filter(
                    event => !state.excludedTags.some(tag => event.tags.includes(tag))
                )
            };
        case Actions.LOAD_PRIVATE:
            let privateEvents;
            if (process.env.isProd) {
                const res = await fetch("content/content.json", {
                    headers: {
                        Authorization: `${btoa(`jan:${action.value}`)}`
                    }
                });
                privateEvents = await res.json();
            } else {
                privateEvents = (await import(`../content/private`)).default;
            }
            const allEvents = orderBy(
                [...state.events, ...privateEvents],
                "date",
                "desc"
            );
            return {
                ...state,
                allEvents,
                events: allEvents,
                loginSuccess: true
            };
        case Actions.TOGGLE_LOGIN:
            return { ...state, loginActive: !state.loginActive };
        case Actions.CHANGE_MODE:
            return { ...state, mode: action.value };
        case Actions.EXCLUDE_TAG:
            const excludedTags = [...state.excludedTags, action.value];
            return {
                ...state,
                excludedTags,
                events: state.allEvents.filter(
                    event => !excludedTags.some(tag => event.tags.includes(tag))
                )
            };
        case Actions.TOGGLE_ERROR:
            return {
                ...state,
                loginError: !state.loginError
            };
        case Actions.INCLUDE_TAG:
            const tags = state.excludedTags.filter(tag => tag !== action.value);
            return {
                ...state,
                excludedTags: tags,
                events: state.allEvents.filter(
                    event => !tags.some(tag => event.tags.includes(tag))
                )
            };
        default:
            return state;
    }
};

const dispatch = async (action: Action) => {
    globalState = await simpleGlobalReducer(globalState, action);
    listeners.slice().forEach(l => l());
    return action;
};

function getState() {
    return globalState;
}

function subscribe(listener: any) {
    listeners.push(listener);
    return () => (listeners = listeners.filter(l => l !== listener));
}

function getInitState() {
    const initState = {
        isLoading: true,
        loginActive: false,
        password: "",
        allEvents: [],
        events: [],
        loginSuccess: false,
        mode: "leger",
        excludedTags: [],
        loginError: false
    };
    let hashState;
    try {
        hashState = JSON.parse(atob(document.location.hash.substring(1)));
    } catch (ex) {
        hashState = undefined;
    }
    return { ...initState, ...hashState };
}

export { dispatch, getState, subscribe, GlobalAppState, Actions };
